<template>
  <div>
    <main>
      <HeaderNavInsights />

      <div class="insights-header">
        <div class="insights-header-info">
          <h1>
            Insights
          </h1>
          <p class="fw-semibold">
            Learn how top creators are increasing their earnings and offering amazing experiences for their fans.
          </p>
        </div>
      </div>

      <div class="latest-creator">
        <router-link to="/angwalangkwentangpodcast" style="text-decoration: none; color: black;">
          <div class="latest-creator-info">
            <img src="/img/awkp-min.png" class="card-img-top w-100" alt="...">
            <div class="card-body">
              <h1 class="card-title fw-bold h1">
                Ang Walang Kwentang Podcast!
              </h1>
              <p class="card-text fw-semibold opacity-50 line-height-4">
                A Spotify Podcast by Filmmakers and real-life friends Antoinette Jadaone and JP Habac.
              </p>
            </div>
          </div>
        </router-link>
      </div>

      <div class="title section-title">
        <h1 class="fw-bold">
          Creators Profile
        </h1>
      </div>
      <div id="mobileOnly" class="scrolling snaps-inline">
        <div class="scroll-element">
          <div class="card" onclick="window.open('https://maica.kumu.club/')">
            <img src="/img/maica.jpg" class="card-img-top" alt="...">
            <div class="card-body">
              <h5 class="card-title fw-bold">Maica Bermejo</h5>
              <p class="card-text fw-semibold opacity-50 line-height-4">A KUMU Live streamer and a content creator.</p>
            </div>
          </div>
        </div>
        <div class="scroll-element">
          <div class="card" onclick="window.open('https://karlthoc.memberships.ph/')">
            <img src="/img/limpin.jpg" class="card-img-top" alt="...">
            <div class="card-body">
              <h5 class="card-title fw-bold">Karl Limpin</h5>
              <p class="card-text fw-semibold opacity-50 line-height-4">Content creator from The House of Collab.</p>
            </div>
          </div>
        </div>
        <div class="scroll-element">
          <div class="card" onclick="window.open('https://patreonsaints.thekoolpals.com/')">
            <div class="card">
              <img src="/img/koolpals-min.jpg" class="card-img-top" alt="...">
              <div class="card-body">
                <h5 class="card-title fw-bold">The KoolPals</h5>
                <p class="card-text fw-semibold opacity-50 line-height-4">One of the comedy podcast in Philippines.</p>
              </div>
            </div>
          </div>
        </div>
        <div class="scroll-element">
          <router-link to="/spart" style="text-decoration: none; color: black;">
            <div class="card">
              <img src="/img/spart-min.jpg" class="card-img-top" alt="...">
              <div class="card-body">
                <h5 class="card-title fw-bold">Spart</h5>
                <p class="card-text fw-semibold opacity-50 line-height-4">A Youtuber known for his unique adventure.</p>
              </div>
            </div>
          </router-link>
        </div>
        <div class="scroll-element">
          <div class="card" onclick="window.open('https://jointhefam.adamalejo.com/')">
            <img src="/img/adamalejo-min.jpg" class="card-img-top" alt="...">
            <div class="card-body">
              <h5 class="card-title fw-bold">Adam Alejo</h5>
              <p class="card-text fw-semibold opacity-50 line-height-4">Youtuber who uploads a wide variety of vlogs.</p>
            </div>
          </div>
        </div>
        <div class="scroll-element">
          <div class="card" onclick="window.open('https://laurenyoung.helixpay.ph/')">
            <img src="/img/lauren-min.jpg" class="card-img-top" alt="...">
            <div class="card-body">
              <h5 class="card-title fw-bold">Lauren Young</h5>
              <p class="card-text fw-semibold opacity-50 line-height-4">Actress, influencer, and an avid gamer.</p>
            </div>
          </div>
        </div>
        <div class="scroll-element">
          <div class="card" onclick="window.open('https://nisanuggets.helixpay.ph/')">
            <img src="/img/nuggets-min.jpg" class="card-img-top" alt="...">
            <div class="card-body">
              <h5 class="card-title fw-bold">Nisa Nuggets</h5>
              <p class="card-text fw-semibold opacity-50 line-height-4">A youtuber who films vlogs about her life.</p>
            </div>
          </div>
        </div>
      </div>

      <div class="title section-title">
        <h1 class="fw-bold">
          Success Stories
        </h1>
      </div>
      <div id="mobileOnly" class="scrolling snaps-inline">
        <div class="scroll-element">
          <router-link to="/spart" style="text-decoration: none; color: black;">
            <div class="card">
              <img src="/img/spart-min.jpg" class="card-img-top" alt="...">
              <div class="card-body">
                <h5 class="card-title fw-bold">Spart</h5>
                <p class="card-text fw-semibold opacity-50 line-height-4">A Youtuber known for his unique adventure.</p>
              </div>
            </div>
          </router-link>
        </div>
        <div class="scroll-element">
          <router-link to="/angwalangkwentangpodcast" style="text-decoration: none; color: black;">
            <div class="card">
              <img src="/img/awkp-min.png" class="card-img-top" alt="...">
              <div class="card-body">
                <h5 class="card-title fw-bold">AWKP</h5>
                <p class="card-text fw-semibold opacity-50 line-height-4">Podcast by Antoinette Jadaone & JP Habac.</p>
              </div>
            </div>
          </router-link>
        </div>
      </div>

      <!-- BLOG POSTS ON MOBILE -->
      <div class="title section-title py-5">
        <h1 class="fw-bold">
          Blog Posts
        </h1>

        <!-- Email Blast Feature -->

        <div class="blog-post-card mt-3" data-bs-toggle="collapse" href="#multiCollapseExample8" role="button" aria-expanded="false" aria-controls="multiCollapseExample7">
          <div>
            <img src="/img/awkp-min.png" class="card-img-top" alt="...">
          </div>
          <div style="background-color: black; height: 100%; width: 1px; margin: auto;"></div>
          <div>
            <div style="display: grid;">
              <p style="color: red; font-weight: 700;">
                <i>New!</i>
              </p>
              <p class="fw-semibold">
                Ang Walang Kwentang Podcast
              </p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div id="multiCollapseExample8" class="collapse multi-collapse">
              <div class="dropdown-output card-body fw-semibold">
                <div class="h4 fw-bold">
                  Background
                </div>
                <p class="fw-semibold opacity-50">
                  <br>
                  Since the launch of Ang Walang Kwentang Podcast’s community through
                  HelixPay, all the subscribers have been actively participating in
                  their private Discord server and live recording of some podcast
                  episodes. One of the main reasons why people stick is that Direk
                  Tonet and Direk JP are also active in the community. They interact
                  with their subscribers and they hear them through their feedback and
                  suggestions.
                  <br>
                  <br>
                  For their biggest live recording YET, the Direks asked their
                  community about who their dream guests are, and the common
                  answers were their two frequent guests: Milo Elmido Jr. and
                  Inah Evans. The Direks saw the suggestion and made it possible!
                </p>
                <div class="h4 fw-bold">
                  Promotion
                </div>
                <p class="fw-semibold opacity-50">
                  <br>
                  Since the announcement, we saw an increase in people joining to
                  become VVIP/VIP members. We also saw a lot of return customers
                  because of the live recording. This gave us the opportunity to
                  test out offering a one-time ticket for the most-awaited live
                  recording five days before the event.
                  <br>
                  <br>
                  To entice more people, especially the ones who are scared of commitment,
                  to purchase and join, we had the following USPs or Unique Selling Points:
                  <br>
                  <br>
                  <ul>
                    <li>
                      If they don’t want to feel FOMO but are also scared of commitment
                      (subscription), they should avail of the one-time ticket.
                    </li>
                    <li>
                      It’s cheaper than subscribing to be a VVIP member (Php150 vs. Php250).
                      But of course, in terms of value, being a member makes more sense.
                    </li>
                    <li>
                      Viewers get to watch their bloopers and the raw version of the recording.
                    </li>
                    <li>
                      There will be a game before the recording where the Direks will
                      giveaway 1-month free subscriptions and free merch.
                    </li>
                  </ul>
                  <br>
                  The event was promoted by the Direks and even the guests once a day
                  and would often repost stories on IG from their fans saying that they
                  bought the tickets already.
                </p>
                <br>
                <div class="h4 fw-bold">
                  Live Recording
                </div>
                <p class="fw-semibold opacity-50">
                  <br>
                  Moments before the event went live, there were still a lot of
                  last-minute buyers who didn’t want to miss out on AWKP’s biggest
                  live recording yet. And as expected, the event delivered - there
                  were over 630 attendees, almost 3x their usual audience. A lot of
                  people took photos / short recordings to be posted on their social
                  media accounts, which were also being reposted by AWKP.
                  <br>
                  <br>
                  To conclude, offering options for your fan/followers help bring in
                  more potential subscribers. AWKP also didn’t fail to make their
                  existing subscribers feel special since access to live recordings
                  is one of the many perks they get out of their VVIP subscription.
                  For those who only purchased the one-time ticket, the event gave them
                  a glimpse of what it’s like to be part of AWKP’s hyper-engaged community.
                  <br>
                  <br>
                  Do you want to execute something similar for your community or most
                  loyal fans? Contact your Account Manager.
                  <!-- Do you want to execute something similar for your community or most
                  loyal fans? Contact your Account Manager or you can schedule a call
                  with our team via this link: -->
                  <br>
                  <br>
                </p>
                <!-- <p class="fw-semibold opacity-50" style="cursor: pointer;" onclick="window.open('https://dashboard.meetbit.io/meeting/HelixPay')">
                  Click here!
                </p> -->
              </div>
            </div>
          </div>
        </div>

        <!-- Email Blast Feature -->

        <div class="blog-post-card mt-3" data-bs-toggle="collapse" href="#multiCollapseExample7" role="button" aria-expanded="false" aria-controls="multiCollapseExample7">
          <div>
            <img src="/img/hp-black-logo.jpg" class="card-img-top" alt="...">
          </div>
          <div style="background-color: black; height: 100%; width: 1px; margin: auto;"></div>
          <div>
            <div>
              <p class="fw-semibold">
                Email Blast Feature
              </p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div id="multiCollapseExample7" class="collapse multi-collapse">
              <div class="dropdown-output card-body fw-semibold">
                <div class="h4 fw-bold">
                  Driving conversions and higher retention with the email blast feature.
                </div>
                <p class="fw-semibold opacity-50">
                  <br>
                  The email blast feature helps you send out an email to a group of customers all at once.
                  No need for MailChimp, Klaviyo… or other CRM tools. Plus, you don’t get a monthly cap
                  for the emails you want to send out. You can use this feature to announce a new product/service,
                  update customers with the latest deals, or to promote a limited-time offer.
                </p>
                <div class="h4 fw-semibold mt-5">
                  Best ways to use it
                </div>
                <div class="h5 fw-semibold mt-5">
                  New content announcement
                </div>
                <p class="fw-semibold opacity-50 mt-2">
                  You have a new Youtube video? Announce it. You just released a new song or podcast episode?
                  Announce it. Make your announcements extra special to your loyal customers or subscribers
                  by maybe giving them early access. This will help drive more new subscribers and keep
                  healthy retention rates.
                </p>
                <div class="h5 fw-semibold mt-5">
                  Newsletter or diary
                </div>
                <p class="fw-semibold opacity-50 mt-2">
                  The HelixPay blast feature is perfect that can serve as your own exclusive blog or newsletter.
                  This comes completely free with the console - no need to purchase email marketing plans like
                  MailChimp, Klaviyo, and others. You also have the freedom to filter your recipients. This can
                  be an added inclusion or perk to subscription plans - all up to you!
                </p>
                <div class="h5 fw-semibold mt-5">
                  Merch drop announcement
                </div>
                <p class="fw-semibold opacity-50 mt-4">
                  You basically own an ecommerce business with your HelixPay storefront. If you’re open to explore
                  offering shippable items, selling merch items is one way to drive growth and amplify your brand
                  Once you have built a list of customers who’ve purchased/subscribed to any of your products, you
                  can send out an email blast to them announcing merch drops and even exclusive discounts/promotions
                  for your one-time offers.
                </p>
                <div class="h5 fw-semibold mt-5">
                  Promotions
                </div>
                <p class="fw-semibold opacity-50 mt-4">
                  Do you want to ride the 11.11, 12.12, 3.3 discounts? You can. Want to offer special discounts to
                  those who bought a ticket to that one workshop event you did? You can. You have the option to
                  send out unique voucher codes to your subscribers/customers and filter it out to the customer
                  segments you’d want to reach.
                </p>
                <div class="h5 fw-semibold mt-5">
                  In Case You Missed Out (ICYMI) / What to expect
                </div>
                <p class="fw-semibold opacity-50 mt-4">
                  Announce special events / share a calendar of events to expect in the coming week/s to your
                  customers. This will keep your subscribers or customers excited for the perks they will get,
                  making the experience much better.
                </p>

                <div class="h4 fw-bold mt-5">
                  Inspos from our partners
                </div>

                <!-- Yardstick -->
                <div class="partners-mobile mt-3">
                  <div class="partners-left">
                    <div class="h5 fw-semibold mt-3">
                      Yardstick Coffee Club (YCC)
                    </div>
                    <p class="fw-semibold opacity-50 mt-4">
                      With HelixPay, Yardstick built a community for their most customers. In the program,
                      members can subscribe and save to their products and other exclusive merch. They use
                      the Email Blast feature for any announcements, and recently they dropped an exclusive
                      YCC merch - the Coffee Person cap.
                    </p>
                  </div>
                  <div class="partners-right">
                    <img src="/img/yardstick-blog.jpeg" class="card-img-top" alt="...">
                  </div>
                </div>

                <!-- AWKP -->
                <div class="partners-mobile mt-4">
                  <div class="partners-left">
                    <div class="h5 fw-semibold mt-3">
                      Ang Walang Kwentang Podcast
                    </div>
                    <p class="fw-semibold opacity-50 mt-4">
                      AWKP’s starter pücks (or their merch) played a big role in the success of their website launch.
                      They have also built a solid (and thriving) community of pückers, who get to enjoy extra
                      discount to their merch.
                      <br>
                      AWKP makes use of the email blast feature to remind the pückers to use their unique code,
                      enabling them to a 5% discount on all merch items. They also used the email blast feature
                      to announce their free shipping promo during the 9.9 weekend. No need for Lazada or Shopee
                      partnership - you can do it all on HelixPay.
                    </p>
                  </div>
                  <div class="partners-right">
                    <img src="/img/awkp-blog.png" class="card-img-top" alt="...">
                  </div>
                </div>

                <!-- Adam Alejo -->
                <div class="partners-mobile mt-4">
                  <div class="partners-left">
                    <div class="h5 fw-semibold mt-3">
                      Adam Alejo
                    </div>
                    <p class="fw-semibold opacity-50 mt-4">
                      Sending out important dates to remember for the new month helps drive higher retention.
                      This gives them an idea of know what to expect and the perks that they get out of their
                      subscription.
                    </p>
                  </div>
                  <div class="partners-right">
                    <img src="/img/adamalejo-blog.png" class="card-img-top" alt="...">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- VIY CORTEZ -->

        <div class="blog-post-card mt-3" data-bs-toggle="collapse" href="#multiCollapseExample0" role="button" aria-expanded="false" aria-controls="multiCollapseExample0">
          <div>
            <img src="/img/viy-icon.jpg" class="card-img-top" alt="...">
          </div>
          <div style="background-color: black; height: 100%; width: 1px; margin: auto;"></div>
          <div>
            <div>
              <p class="fw-semibold">
                Viy Cortez
              </p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div id="multiCollapseExample0" class="collapse multi-collapse">
              <div class="dropdown-output card-body fw-semibold opacity-50">
                <br>
                As the partner of Lincoln Velasquez (Cong TV), almost anyone who’s familiar with vlogging in the Philippines knows that Viy Cortez is one of the most successful and influential content creators and businesswomen in the country.
                <br>
                <br>
                With funny and entertaining videos online, Viy has accumulated and continues to grow her 6.1 million subscribers on YouTube. Her channel encapsulates her daily life inside the Payamansion ranging from mundane things like cooking with her boyfriend, Cong, to doing make-up tutorials to facilitating friendly challenges and pranks and many more!
                <br>
                <br>
                With a positive outlook in life and the support of people around her, Viy established herself as a successful influencer, landing brand deals and amassing wealth from her humble beginnings. Amongst other things, she built her parents their dream house with the money she earned. And just recently she also started the construction of her own dream house with long-time boyfriend, now fiancé, Cong.
                However, unbeknownst to many, a huge part of Viy’s success is her entrepreneurial mindset – being super creative in her contents but also not wanting to be complacent and dependent on social media platforms as her lone source of income. She sought for a more sustainable way to earn and founded her own cosmetic line, Viy Line Cosmetics. This project that started with a 10k PHP investment from Cong, eventually grew to become the Viy Group of companies – expanding from cosmetics to clothing, skincare and even food & beverages.
                <br>
                <br>
                Viy’s rising fame in what seemed back then to be non-traditional media, YouTube, has put her in a position of influence on par with that of a celebrity. What started off as an independent creator channel has evolved into a full-blown brand behind her massive online following. But it didn’t have to be big for her to start her venture.
                <br>
                <br>
                When she started Viy Line back in 2019, her subscriber count was only within the six-digit (100k-250k) range. However, by recognizing that her relationship and engagement with her audience goes beyond the screen, she saw a huge opportunity in building out a business through her community. Since she started her channel with makeup tutorials, it made sense to create a cosmetic line.  She carved an entry point to the market through her engaged fans and now Viy Line Cosmetics can stand on its own, growing with her online presence, and generating millions of pesos annually.
                <br>
                <br>
                This is just one of many examples of creators expanding from content creation to become successful entrepreneurs. Vloggers and social media personalities are garnering equal and sometimes even more viewership than TV productions. 20 years ago, ads spaces were bought from TV networks for commercial air time. 10 years ago, brands capitalizing on the followers and subscribers of influencers offered them content sponsorships for product promotion. Now more than ever, content creators, with advancements in e-commerce adoption, are in the position to promote their OWN brand instead of others.
                <br>
                <br>
                HelixPay is building Philippine’s first e-commerce technology for content creators enabling you to easily bring your ideas to life! We have already helped some of the biggest creators in the country to launch their ecommerce businesses. If you have any physical / digital products in mind, we can help you bring this to market using a combination of our proprietary technology and our professional network. It’s time to grasp this opportunity and influence your own future!
              </div>
            </div>
          </div>
        </div>

        <!-- Upload Videos -->

        <div class="blog-post-card mt-3" data-bs-toggle="collapse" href="#multiCollapseExample1" role="button" aria-expanded="false" aria-controls="multiCollapseExample1">
          <div>
            <img src="/img/hp-black-logo.jpg" class="card-img-top" alt="...">
          </div>
          <div style="background-color: black; height: 100%; width: 1px; margin: auto;"></div>
          <div>
            <p class="card-title fw-semibold">
              Upload Videos & Blogs on your HelixPay Page
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div id="multiCollapseExample1" class="collapse multi-collapse">
              <div class="dropdown-output card-body fw-semibold opacity-50">
                Hey creator!
                <br>
                <br>
                Want to offer an exclusive type of fan memberships for your followers? Well, HelixPay can help you with that! You can create your own exclusive, private, and member only page. In this page you can create various types of content: videos, blog posts, podcasts, and comics. Since this is a completely exclusive feed, your customers and fans can pay a certain amount of money for content that won’t be available anywhere else. This access is customizable depending on your different membership tiers or packages/programs. For instance, for a basic membership you can add three videos, for a standard membership 5 videos, but for a premium membership or your highest tier, you can add 10 videos with a blog post and a comic. Every content can be filtered and only those who bought your membership can see this content. All of these details are fully customizable according to your plans and preferences.
                <br>
                <br>
                This can empower you as a creator to build your own Netflix-style video on demand experience. For Youtubers and Streamers, you can offer never seen before content, or you can give your subscribers early releases of your new content. For coaches or gurus, you can offer module lessons or courses. The power is ultimately up to you. This can completely change the way you interact and engage with your fans and empower you to earn big recurring sustainable income.
                <br>
                <br>
                Want to have your own membership page? Request for a demo and schedule a call now!
              </div>
            </div>
          </div>
        </div>

        <!-- Exciting Perks! -->

        <div class="blog-post-card mt-3" data-bs-toggle="collapse" href="#multiCollapseExample2" role="button" aria-expanded="false" aria-controls="multiCollapseExample2">
          <div>
            <img src="/img/hp-black-logo.jpg" class="card-img-top" alt="...">
          </div>
          <div style="background-color: black; height: 100%; width: 1px; margin: auto;"></div>
          <div>
            <p class="card-title fw-semibold">
              Exciting Perks!
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div id="multiCollapseExample2" class="collapse multi-collapse">
              <div class="dropdown-output card-body fw-semibold">
                <p class="opacity-50">
                  Hey creator!
                  <br>
                  <br>
                  Do you feel like you lack content and offerings for your subscribers/fans? Well here are ideas for more features to add for your membership packages!
                  <br>
                  <br>
                  Zoom meetings
                  <br>
                  <br>
                  One way to spice up your content is by setting up Zoom meetups. You can do online shows or live hangouts with your community via Zoom for up to 500 attendees!
                  <br>
                  <br>
                  - Classes - perfect for financial and life coaches, yoga instructors, and beauty gurus.<br>
                  - Live recordings - perfect for streamers and podcasts, where they can invite guests for interviews, play alongs, or roundtable discussions.<br>
                  - Q & A sessions - allow fans or subscribers to ask you about anything or could even just focus on specific topics.
                  <br>
                  <br>
                </p>
                <img src="/img/zoom-meetings.jpg" class="card-img-top" alt="...">
                <p class="opacity-50">
                  <br>
                  <br>
                  Discord Integration
                  <br>
                  <br>
                  Discord is one of the most popular chat apps especially in the gaming and creator communities. HelixPay has a special integration directly with Discord for our creator clients that makes it easy and seamless for your subscribers to join Discord automatically when they have paid for your membership packages. There are many features in Discord; you can customize what your members can access based on their subscription tier. If you have different tiers of membership such as Basic, Standard, and Premium, you can filter out every content and channels available for each subscriber. You can also set up voice channels in your private discord server where other members can chat. And you can schedule activities that would boost engagement such as games, quiz nights, etc.
                  <br>
                  <br>
                </p>
                <img src="img/discord-img-min.png" class="w-100" alt="">
                <p class="opacity-50">
                  <br>
                  <br>
                  Members only discount
                  <br>
                  <br>
                  Aside from offering memberships and subscriptions, you can also sell one time purchases such as merchandise wherein you can create a unique voucher code per member. If you’re an affiliate and have partnerships with various brands, you can also offer special codes that would give them a discount to your partner brands.
                  <br>
                  <br>
                </p>
                <img src="img/awkp-screenshot.jpg" class="w-100" alt="">
                <p class="opacity-50">
                  <br>
                  <br>
                  Access to your member’s page
                  <br>
                  <br>
                  With HelixPay you can upload videos, blogs, and voice notes on your exclusive content page. Apart from the content you put out on your public social media accounts, you can upload extended cuts, behind-the-scenes, bloopers, and never-seen-before content to maintain the exclusivity of your member’s page. You can set which content is available to which people based on your products and membership tiers. For instance, you can offer a subscription that gives access to all of your videos. You can also offer a “single-purchase” product that gives a fan access to one particular video.
                  <br>
                  <br>
                  Customers buy access directly from your storefront. They can watch the videos with a simple log-in via their personal SMS number so it’s easy for fans to watch while still being very secure so only your paying fans can access the content.
                  <br>
                  <br>
                  To conclude, there are many things you can do as a content creator in HelixPay. Request for a demo and schedule a call now!
                </p>
                <span class="fw-bold opacity-50" onclick="window.open('https://arynkingking.com/')">
                  Arynkingking.com
                </span>
              </div>
            </div>
          </div>
        </div>

        <!-- RauxTV -->

        <div class="blog-post-card mt-3" data-bs-toggle="collapse" href="#multiCollapseExample3" role="button" aria-expanded="false" aria-controls="multiCollapseExample3">
          <div>
            <img src="/img/raux-min.jpg" class="card-img-top" alt="...">
          </div>
          <div style="background-color: black; height: 100%; width: 1px; margin: auto;"></div>
          <div>
            <p class="card-title fw-semibold">
              RauxTV joins HelixPay!
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div id="multiCollapseExample3" class="collapse multi-collapse">
              <div class="dropdown-output card-body fw-semibold">
                <p class="opacity-50">
                  Hey Rifters!
                  <br>
                  <br>
                  Mga dude pare, Raux, has now launched his own website! After his prolific career in League of Legends - PC and his recent stint in WildRift as the support for Team Secret, Raux has decided to share his love for the game to his followers through highly engaging and super chill streams. He still plays WildRift, boasting highly skilled Challenger-level matches on his Facebook page/ Twitch channels. Catch him live on your regular late afternoon with his over 60k followers! All the while demonstrating his prowess, Raux continues to inspire on-stream as he engages with his audience very openly, especially when educating them about the game.
                  <br>
                  <br>
                  Visit his storefront now - rauxtv.helixpay.ph
                  <br>
                  <br>
                </p>
                <img src="/img/raux-stream.png" class="card-img-top w-100" alt="...">
                <p class="opacity-50">
                  <br>
                  <br>
                  With nine years of playing League under his belt, a knack for teaching, and a chill tone guiding his teammates, aspiring players have from a range of skill levels reached out to him for coaching services. Continuously looking for ways to connect with his fans and promote the game, he created a website together with HelixPay to compile all his memberships, tutorials, and fan experiences together. In just one website, Raux can easily manage his services enabling him to run a business and pursue a streaming career seamlessly!
                  <br>
                  <br>
                </p>
                <img src="/img/raux-screenshot-min.jpg" class="card-img-top w-100" alt="...">
                <p class="opacity-50">
                  <br>
                  <br>
                  Are you a streamer like Raux? Book a call with us now!
                </p>
              </div>
            </div>
          </div>
        </div>

        <!-- Arynkingking -->

        <div class="blog-post-card mt-3" data-bs-toggle="collapse" href="#multiCollapseExample4" role="button" aria-expanded="false" aria-controls="multiCollapseExample4">
          <div>
            <img src="/img/aryn-min.jpg" class="card-img-top" alt="...">
          </div>
          <div style="background-color: black; height: 100%; width: 1px; margin: auto;"></div>
          <div>
            <p class="card-title fw-semibold">
              Arynkingking launches 8-bit HelixPay storefront
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div id="multiCollapseExample4" class="collapse multi-collapse">
              <div class="dropdown-output card-body fw-semibold">
                <p class="opacity-50">
                  Aryn Cristobal or Arynkingking is finally launching her own Helixpay storefront! Vlogger, podcaster, singer, and many more - she loves creating comedic and relatable content for millennials. She does everything from improvising songs, talking about the latest TV shows, and sharing love advice. Her style and content have made her one of the most popular personalities in the country!
                  <br>
                  <br>
                </p>
                <img src="/img/aryn-screenshot.jpg" class="card-img-top w-100" alt="...">
                <p class="opacity-50">
                  <br>
                  <br>
                  On her storefront, fans can easily buy and enjoy unique experiences. She’s launching exciting fan subscription packages with three membership tiers, where her fans can join her private Discord community and they’ll also gain access to her exclusive content. She has also set up a tip jar where you can give her any amount, starting at Php 50. Another exciting offering from Aryn is the chance to have a 15-minute chat with her. You can ask her anything or you can also do an improv game with her as you record. This also entitles you to a discount at Third World Improv. Exciting, right?
                  <br>
                  <br>
                  Her offerings range from just P50 up to P300. If you’re one of her big fans, head on to her site right now  - Arynkingking.com
                </p>
              </div>
            </div>
          </div>
        </div>

        <!-- THOC -->

        <div class="blog-post-card mt-3" data-bs-toggle="collapse" href="#multiCollapseExample5" role="button" aria-expanded="false" aria-controls="multiCollapseExample5">
          <div>
            <img src="/img/thoc-logo.jpg" class="card-img-top" alt="...">
          </div>
          <div style="background-color: black; height: 100%; width: 1px; margin: auto;"></div>
          <div>
            <p class="card-title fw-semibold">
              HelixPay provides e-commerce technology for The House Of Collab
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div id="multiCollapseExample5" class="collapse multi-collapse">
              <div class="dropdown-output card-body fw-semibold">
                <p class="opacity-50">
                  Hey THOC fans, we have exciting news for you!
                </p>
                <br>
                <p class="opacity-50">
                  House of Talents is an online reality show of content creators living together in a mansion. Currently, they are on its third season and millions of people have tuned in over the past few months. The group is active across social media platforms - they shoot a 24/7 livestream in Kumu, have scheduled live shows on Twitch, Facebook, and on local TV channels, and post Youtube episodes daily. With over 25 content creators, each one has hundreds of thousands of followers and various partnerships with many brands.
                </p>
                <br>
                <p class="opacity-50">
                  Because of this, many of their fans would message them and ask for requests like an audio or a video shoutout. Remember in that one episode where Evan and Khate had a dancing showdown? Your favorite content creator can send you a video of them dancing or singing as they prepare your own personalized video. Some of the members of House of Talents have recently launched their storefronts such as Karl Limpin, Ward Reinalyn, and Angge Gegante. They make use of HelixPay for easier payment management. Moreover, with HelixPay, you can showcase your own style and branding. With HelixPay’s no code storefronts, it is easy and hassle-free to make your own ecommerce website. You can change anything like the fonts, colors and style seamlessly. No need to learn any sort of software coding!
                </p>
                <img src="/img/thoc-header-pic.png" class="card-img-top" alt="...">
                <br>
                <p class="opacity-50 mt-4">
                  Helixpay has been a helping hand to influencers and content creators to enable their earnings with e-commerce technology. Need help as well? Message us and request for a demo now!
                </p>
              </div>
            </div>
          </div>
        </div>

        <!-- NEW BLOG POST -->

        <div class="blog-post-card mt-3" data-bs-toggle="collapse" href="#multiCollapseExample6" role="button" aria-expanded="false" aria-controls="multiCollapseExample6">
          <div>
            <img src="/img/hp-black-logo.jpg" class="card-img-top" alt="...">
          </div>
          <div style="background-color: black; height: 100%; width: 1px; margin: auto;"></div>
          <div>
            <p class="card-title fw-semibold">
              HelixPay Insights
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div id="multiCollapseExample6" class="collapse multi-collapse">
              <div class="dropdown-output card-body fw-semibold opacity-50">
                Hey creator!
                <br>
                <br>
                Thinking of possible products/services to sell from your HelixPay storefront? There are infinite possibilities  but the first thing we recommend is to sell your simplest products: simple tip jar, shoutouts, fan merch, and exclusive fan subscriptions.
                <br>
                <br>
                Tip jar
                <br>
                <br>
                This is the easiest one to start with. You can start and soft launch a tip jar. A tip jar enables your audience to give back and express appreciation without having to spend a fixed amount - they can give an amount that works for them.
                <br>
                <br>
                Shoutout
                <br>
                <br>
                Most often used by streamers and podcasts, shoutouts are one of the best ways of creating revenue. Shout-out posts help show the connection between you and your subscribers and can help you gain new followers. When you shout out to subscribers/fans, their friends are able to see your post. (mention cameo - creators and celebrities in the US are earning really well from cameo!).
                <br>
                <br>
                Merchandise
                <br>
                <br>
                You can also start selling merchandise. These can be anything ranging from caps, hoodies, stickers, candles, and much more. Moreover, Helixpay has a partnership with local logistics companies in the Philippines.
                <br>
                <br>
                Subscriptions
                <br>
                <br>
                Lastly, you can build a strong community with your fans by setting up different kinds of subscriptions. In these subscriptions you can create an exclusive invite to Discord/Telegram, you can send them a never seen before footage, or you can give them early access to your content.
                <br>
                <br>
                Create your own
                <br>
                <br>
                You can create any type of product or experience to sell from your storefront. It’s easy to customize and add a new product in just minutes. You can read more about how to create a product
                <span class="fw-semibold opacity-50" onclick="window.open('https://docs.helixpay.ph/docs/6-managing-product-offers')">
                  here.
                </span>
                <br>
                <br>
                To conclude, the options are limitless. As long as the prices are affordable enough for your audience, and you are able to market and showcase your storefront to your followers, you are able to set up a profitable and successful venture that generates big sustainable income for your creative pursuits. Book a meeting with us now!
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- INSTAGRAM ON MOBILE -->
      <div id="igMobile" class="list-group p-3">
        <div class="title section-title">
          <img src="/img/ig-img.svg" class="rounded ig-img mb-3" alt="...">
        </div>

        <div id="ig-mobile-photos" class="mb-5">
          <img src="/img/6.jpg" class="ig-thumbnail-mobile" alt="..."
               onclick="window.open('https://www.instagram.com/p/CiGseOJvHKG/')"
          >
          <img src="/img/5.jpg" class="ig-thumbnail-mobile" alt="..."
               onclick="window.open('https://www.instagram.com/p/CiGsZIqvgQ4/')"
          >
          <img src="/img/4.jpg" class="ig-thumbnail-mobile" alt="..."
               onclick="window.open('https://www.instagram.com/p/CiGsRl3vHFr/')"
          >
          <img src="/img/3.jpg" class="ig-thumbnail-mobile" alt="..."
               onclick="window.open('https://www.instagram.com/p/CiGsMS2vUZS/')"
          >
          <img src="/img/2.jpg" class="ig-thumbnail-mobile" alt="..."
               onclick="window.open('https://www.instagram.com/p/CiGsDNwPEGk/')"
          >
          <img src="/img/1.jpg" class="ig-thumbnail-mobile" alt="..."
               onclick="window.open('https://www.instagram.com/p/CiGr9OJPqmK/')"
          >
        </div>
      </div>

      <!-- DESKTOP VIEW -->

      <div class="insights">
        <div class="insights-info">
          <div class="left-part">
            <!-- CATEGORIES -->
            <div class="list-group">
              <span class="floating-title fw-semibold pb-4 h3">
                Categories
              </span>
              <button type="button"
                      class="list-group-item list-group-item-action py-4"
                      @click="layout = 'Podcast'"
              >
                Featured
              </button>
              <button type="button"
                      class="list-group-item list-group-item-action py-4"
                      @click="layout = 'All'"
              >
                All Posts
              </button>
              <button type="button"
                      class="list-group-item list-group-item-action py-4"
                      @click="layout = 'Stories'"
              >
                Success Stories
              </button>
              <button type="button"
                      class="list-group-item list-group-item-action py-4"
                      @click="layout = 'Creators'"
              >
                Creator Tools
              </button>
            </div>

            <!-- TOP POSTS -->
            <div class="list-group mt-5">
              <span class="floating-title fw-semibold p-3 py-4 h3">
                Blog Posts
              </span>
              <button type="button"
                      class="list-group-item list-group-item-action py-4 align-items-center"
                      @click="layout = 'AWKP'"
              >
                <div style="display: flex; justify-content: space-between;">
                  <p>
                    AWKP
                  </p>
                  <p style="color: red; font-weight: 700;">
                    <i>New!</i>
                  </p>
                </div>
              </button>
              <button type="button"
                      class="list-group-item list-group-item-action py-4 align-items-center"
                      @click="layout = 'Collections'"
              >
                Email Blast Feature
              </button>
              <button type="button"
                      class="list-group-item list-group-item-action py-4 align-items-center"
                      @click="layout = 'VMG'"
              >
                Viy Cortez
              </button>
              <button type="button"
                      class="list-group-item list-group-item-action py-4 align-items-center"
                      @click="layout = 'Upload'"
              >
                Upload Videos and Blogs
              </button>
              <button type="button"
                      class="list-group-item list-group-item-action py-4 align-items-center"
                      @click="layout = 'Perks'"
              >
                Exciting Perks
              </button>
              <button type="button"
                      class="list-group-item list-group-item-action py-4 align-items-center"
                      @click="layout = 'Raux'"
              >
                RauxTV launches his HelixPay storefront!
              </button>
              <button type="button"
                      class="list-group-item list-group-item-action py-4 align-items-center"
                      @click="layout = 'Arykingking'"
              >
                Arynkingking launches 8-bit HelixPay storefront
              </button>
              <button type="button"
                      class="list-group-item list-group-item-action py-4 align-items-center"
                      @click="layout = 'THOC'"
              >
                House of Collab
              </button>
              <button type="button"
                      class="list-group-item list-group-item-action py-4 align-items-center"
                      @click="layout = 'HxP Insights'"
              >
                HelixPay Insights
              </button>
            </div>

            <!-- INSTAGRAM -->
            <div class="list-group mt-5">
              <span class="floating-title fw-semibold p-3 py-4 h3">
                <img src="/img/ig-img.svg" class="w-75 rounded" alt="...">
              </span>

              <div id="ig-mobile-photos" class="mb-5">
                <img src="/img/6.jpg" class="ig-thumbnail-mobile" alt="..."
                     onclick="window.open('https://www.instagram.com/p/CiGseOJvHKG/')"
                >
                <img src="/img/5.jpg" class="ig-thumbnail-mobile" alt="..."
                     onclick="window.open('https://www.instagram.com/p/CiGsZIqvgQ4/')"
                >
                <img src="/img/4.jpg" class="ig-thumbnail-mobile" alt="..."
                     onclick="window.open('https://www.instagram.com/p/CiGsRl3vHFr/')"
                >
                <img src="/img/3.jpg" class="ig-thumbnail-mobile" alt="..."
                     onclick="window.open('https://www.instagram.com/p/CiGsMS2vUZS/')"
                >
                <img src="/img/2.jpg" class="ig-thumbnail-mobile" alt="..."
                     onclick="window.open('https://www.instagram.com/p/CiGsDNwPEGk/')"
                >
                <img src="/img/1.jpg" class="ig-thumbnail-mobile" alt="..."
                     onclick="window.open('https://www.instagram.com/p/CiGr9OJPqmK/')"
                >
              </div>
            </div>
          </div>

          <div class="right-part">
            <div v-if="layout === 'All'" class="output-cards m-auto">
              <div class="card" onclick="window.open('https://laurenyoung.helixpay.ph/')">
                <img src="/img/lauren-min.jpg" class="card-img-top" alt="...">
                <div class="card-body">
                  <h5 class="card-title fw-bold">Lauren Young</h5>
                  <p class="card-text fw-semibold opacity-50 line-height-4">Actress, influencer, and an avid gamer.</p>
                </div>
              </div>

              <div class="card" onclick="window.open('https://maica.kumu.club/')">
                <img src="/img/maica.jpg" class="card-img-top" alt="...">
                <div class="card-body">
                  <h5 class="card-title fw-bold">Maica Bermejo</h5>
                  <p class="card-text fw-semibold opacity-50 line-height-4">A KUMU Live streamer and a content creator.</p>
                </div>
              </div>

              <div class="card" onclick="window.open('https://karlthoc.memberships.ph/')">
                <img src="/img/limpin.jpg" class="card-img-top" alt="...">
                <div class="card-body">
                  <h5 class="card-title fw-bold">Karl Limpin</h5>
                  <p class="card-text fw-semibold opacity-50 line-height-4">Content creator from The House of Collab.</p>
                </div>
              </div>

              <div class="card" onclick="window.open('https://nisanuggets.helixpay.ph/')">
                <img src="/img/nuggets-min.jpg" class="card-img-top" alt="...">
                <div class="card-body">
                  <h5 class="card-title fw-bold">Nisa Nuggets</h5>
                  <p class="card-text fw-semibold opacity-50 line-height-4">A filipino youtuber who films quirky vlogs about her life.</p>
                </div>
              </div>

              <div class="card" onclick="window.open('https://patreonsaints.thekoolpals.com/')">
                <img src="/img/koolpals-min.jpg" class="card-img-top" alt="...">
                <div class="card-body">
                  <h5 class="card-title fw-bold">The KoolPals</h5>
                  <p class="card-text fw-semibold opacity-50 line-height-4">One of the comedy podcast in Philippines.</p>
                </div>
              </div>

              <router-link to="/angwalangkwentangpodcast" style="text-decoration: none; color: black;">
                <div class="card">
                  <img src="/img/awkp-min.png" class="card-img-top" alt="...">
                  <div class="card-body">
                    <h5 class="card-title fw-bold">AWKP</h5>
                    <p class="card-text fw-semibold opacity-50 line-height-4">A Spotify Podcast by Filmmakers and real-life friends Antoinette Jadaone & JP Habac</p>
                  </div>
                </div>
              </router-link>

              <router-link to="/spart" style="text-decoration: none; color: black;">
                <div class="card">
                  <img src="/img/spart-min.jpg" class="card-img-top" alt="...">
                  <div class="card-body">
                    <h5 class="card-title fw-bold">Spart</h5>
                    <p class="card-text fw-semibold opacity-50 line-height-4">A Youtuber known for his unique adventure.</p>
                  </div>
                </div>
              </router-link>

              <div class="card" onclick="window.open('https://jointhefam.adamalejo.com/')">
                <img src="/img/adamalejo-min.jpg" class="card-img-top" alt="...">
                <div class="card-body">
                  <h5 class="card-title fw-bold">Adam Alejo</h5>
                  <p class="card-text fw-semibold opacity-50 line-height-4">Youtuber who uploads a wide variety of vlogs.</p>
                </div>
              </div>
            </div>

            <div v-if="layout === 'Podcast'" class="output-cards m-auto">
              <router-link to="/angwalangkwentangpodcast" style="text-decoration: none; color: black;">
                <div class="card">
                  <img src="/img/awkp-min.png" class="card-img-top" alt="...">
                  <div class="card-body">
                    <h5 class="card-title fw-bold">AWKP</h5>
                    <p class="card-text fw-semibold opacity-50 line-height-4">A Spotify Podcast by Filmmakers and real-life friends Antoinette Jadaone & JP Habac</p>
                  </div>
                </div>
              </router-link>
            </div>

            <div v-if="layout === 'Creators'" class="output-cards m-auto">
              <div class="card" onclick="window.open('https://maica.kumu.club/')">
                <img src="/img/maica.jpg" class="card-img-top" alt="...">
                <div class="card-body">
                  <h5 class="card-title fw-bold">Maica Bermejo</h5>
                  <p class="card-text fw-semibold opacity-50 line-height-4">A KUMU Live streamer and a content creator.</p>
                </div>
              </div>

              <div class="card" onclick="window.open('https://karlthoc.memberships.ph/')">
                <img src="/img/limpin.jpg" class="card-img-top" alt="...">
                <div class="card-body">
                  <h5 class="card-title fw-bold">Karl Limpin</h5>
                  <p class="card-text fw-semibold opacity-50 line-height-4">Content creator from The House of Collab.</p>
                </div>
              </div>

              <div class="card" onclick="window.open('https://patreonsaints.thekoolpals.com/')">
                <img src="/img/koolpals-min.jpg" class="card-img-top" alt="...">
                <div class="card-body">
                  <h5 class="card-title fw-bold">The KoolPals</h5>
                  <p class="card-text fw-semibold opacity-50 line-height-4">One of the comedy podcast in Philippines.</p>
                </div>
              </div>

              <router-link to="/spart" style="text-decoration: none; color: black;">
                <div class="card">
                  <img src="/img/spart-min.jpg" class="card-img-top" alt="...">
                  <div class="card-body">
                    <h5 class="card-title fw-bold">Spart</h5>
                    <p class="card-text fw-semibold opacity-50 line-height-4">A Youtuber known for his unique adventure.</p>
                  </div>
                </div>
              </router-link>

              <div class="card" onclick="window.open('https://jointhefam.adamalejo.com/')">
                <img src="/img/adamalejo-min.jpg" class="card-img-top" alt="...">
                <div class="card-body">
                  <h5 class="card-title fw-bold">Adam Alejo</h5>
                  <p class="card-text fw-semibold opacity-50 line-height-4">Youtuber who uploads a wide variety of vlogs.</p>
                </div>
              </div>

              <div class="card" onclick="window.open('https://laurenyoung.helixpay.ph/')">
                <img src="/img/lauren-min.jpg" class="card-img-top" alt="...">
                <div class="card-body">
                  <h5 class="card-title fw-bold">Lauren Young</h5>
                  <p class="card-text fw-semibold opacity-50 line-height-4">Actress, influencer, and an avid gamer.</p>
                </div>
              </div>

              <div class="card" onclick="window.open('https://nisanuggets.helixpay.ph/')">
                <img src="/img/nuggets-min.jpg" class="card-img-top" alt="...">
                <div class="card-body">
                  <h5 class="card-title fw-bold">Nisa Nuggets</h5>
                  <p class="card-text fw-semibold opacity-50 line-height-4">A filipino youtuber who films quirky vlogs about her life.</p>
                </div>
              </div>
            </div>

            <div v-if="layout === 'Stories'" class="output-cards m-auto">
              <router-link to="/spart" style="text-decoration: none; color: black;">
                <div class="card">
                  <img src="/img/spart-min.jpg" class="card-img-top" alt="...">
                  <div class="card-body">
                    <h5 class="card-title fw-bold">Spart</h5>
                    <p class="card-text fw-semibold opacity-50 line-height-4">A Youtuber known for his unique adventure.</p>
                  </div>
                </div>
              </router-link>

              <router-link to="/angwalangkwentangpodcast" style="text-decoration: none; color: black;">
                <div class="card">
                  <img src="/img/awkp-min.png" class="card-img-top" alt="...">
                  <div class="card-body">
                    <h5 class="card-title fw-bold">AWKP</h5>
                    <p class="card-text fw-semibold opacity-50 line-height-4">A Spotify Podcast by Filmmakers and real-life friends Antoinette Jadaone & JP Habac</p>
                  </div>
                </div>
              </router-link>
            </div>

            <!-- STORIES -->

            <div v-if="layout === 'AWKP'" class="output-stories m-auto">
              <div class="card p-5">
                <div class="h4 fw-bold">
                  Background
                </div>
                <p class="fw-semibold opacity-50">
                  <br>
                  Since the launch of Ang Walang Kwentang Podcast’s community through
                  HelixPay, all the subscribers have been actively participating in
                  their private Discord server and live recording of some podcast
                  episodes. One of the main reasons why people stick is that Direk
                  Tonet and Direk JP are also active in the community. They interact
                  with their subscribers and they hear them through their feedback and
                  suggestions.
                  <br>
                  <br>
                  For their biggest live recording YET, the Direks asked their
                  community about who their dream guests are, and the common
                  answers were their two frequent guests: Milo Elmido Jr. and
                  Inah Evans. The Direks saw the suggestion and made it possible!
                </p>
                <br>
                <div class="h4 fw-bold">
                  Promotion
                </div>
                <p class="fw-semibold opacity-50">
                  <br>
                  Since the announcement, we saw an increase in people joining to
                  become VVIP/VIP members. We also saw a lot of return customers
                  because of the live recording. This gave us the opportunity to
                  test out offering a one-time ticket for the most-awaited live
                  recording five days before the event.
                  <br>
                  <br>
                  To entice more people, especially the ones who are scared of commitment,
                  to purchase and join, we had the following USPs or Unique Selling Points:
                  <br>
                  <br>
                  <ul>
                    <li>
                      If they don’t want to feel FOMO but are also scared of commitment
                      (subscription), they should avail of the one-time ticket.
                    </li>
                    <li>
                      It’s cheaper than subscribing to be a VVIP member (Php150 vs. Php250).
                      But of course, in terms of value, being a member makes more sense.
                    </li>
                    <li>
                      Viewers get to watch their bloopers and the raw version of the recording.
                    </li>
                    <li>
                      There will be a game before the recording where the Direks will
                      giveaway 1-month free subscriptions and free merch.
                    </li>
                  </ul>
                  <br>
                  The event was promoted by the Direks and even the guests once a day
                  and would often repost stories on IG from their fans saying that they
                  bought the tickets already.
                </p>
                <br>
                <div class="h4 fw-bold">
                  Live Recording
                </div>
                <p class="fw-semibold opacity-50">
                  <br>
                  Moments before the event went live, there were still a lot of
                  last-minute buyers who didn’t want to miss out on AWKP’s biggest
                  live recording yet. And as expected, the event delivered - there
                  were over 630 attendees, almost 3x their usual audience. A lot of
                  people took photos / short recordings to be posted on their social
                  media accounts, which were also being reposted by AWKP.
                  <br>
                  <br>
                  To conclude, offering options for your fan/followers help bring in
                  more potential subscribers. AWKP also didn’t fail to make their
                  existing subscribers feel special since access to live recordings
                  is one of the many perks they get out of their VVIP subscription.
                  For those who only purchased the one-time ticket, the event gave them
                  a glimpse of what it’s like to be part of AWKP’s hyper-engaged community.
                  <br>
                  <br>
                  Do you want to execute something similar for your community or most
                  loyal fans? Contact your Account Manager.
                  <!-- Do you want to execute something similar for your community or most
                  loyal fans? Contact your Account Manager or you can schedule a call
                  with our team via this link: -->
                  <br>
                  <br>
                </p>
                <!-- <p class="fw-semibold opacity-50" style="cursor: pointer;" onclick="window.open('https://dashboard.meetbit.io/meeting/HelixPay')">
                  Click here!
                </p> -->
              </div>
            </div>

            <div v-if="layout === 'Collections'" class="output-stories m-auto">
              <div class="card p-5">
                <div class="h4 fw-bold">
                  Driving conversions and higher retention with the email blast feature.
                </div>
                <p class="fw-semibold opacity-50">
                  <br>
                  The email blast feature helps you send out an email to a group of customers all at once.
                  No need for MailChimp, Klaviyo… or other CRM tools. Plus, you don’t get a monthly cap
                  for the emails you want to send out. You can use this feature to announce a new product/service,
                  update customers with the latest deals, or to promote a limited-time offer.
                </p>
                <div class="h4 fw-semibold mt-3">
                  Best ways to use it
                </div>
                <div class="h5 fw-semibold mt-3">
                  New content announcement
                </div>
                <p class="fw-semibold opacity-50">
                  You have a new Youtube video? Announce it. You just released a new song or podcast episode?
                  Announce it. Make your announcements extra special to your loyal customers or subscribers
                  by maybe giving them early access. This will help drive more new subscribers and keep
                  healthy retention rates.
                </p>
                <div class="h5 fw-semibold mt-3">
                  Newsletter or diary
                </div>
                <p class="fw-semibold opacity-50">
                  The HelixPay blast feature is perfect that can serve as your own exclusive blog or newsletter.
                  This comes completely free with the console - no need to purchase email marketing plans like
                  MailChimp, Klaviyo, and others. You also have the freedom to filter your recipients. This can
                  be an added inclusion or perk to subscription plans - all up to you!
                </p>
                <div class="h5 fw-semibold mt-3">
                  Merch drop announcement
                </div>
                <p class="fw-semibold opacity-50">
                  You basically own an ecommerce business with your HelixPay storefront. If you’re open to explore
                  offering shippable items, selling merch items is one way to drive growth and amplify your brand
                  Once you have built a list of customers who’ve purchased/subscribed to any of your products, you
                  can send out an email blast to them announcing merch drops and even exclusive discounts/promotions
                  for your one-time offers.
                </p>
                <div class="h5 fw-semibold mt-3">
                  Promotions
                </div>
                <p class="fw-semibold opacity-50">
                  Do you want to ride the 11.11, 12.12, 3.3 discounts? You can. Want to offer special discounts to
                  those who bought a ticket to that one workshop event you did? You can. You have the option to
                  send out unique voucher codes to your subscribers/customers and filter it out to the customer
                  segments you’d want to reach.
                </p>
                <div class="h5 fw-semibold mt-3">
                  In Case You Missed Out (ICYMI) / What to expect
                </div>
                <p class="fw-semibold opacity-50">
                  Announce special events / share a calendar of events to expect in the coming week/s to your
                  customers. This will keep your subscribers or customers excited for the perks they will get,
                  making the experience much better.
                </p>
                <div class="h4 fw-bold mt-5">
                  Inspos from our partners
                </div>

                <!-- Yardstick -->
                <div class="partners mt-3">
                  <div class="partners-left">
                    <div class="h5 fw-semibold mt-3">
                      Yardstick Coffee Club (YCC)
                    </div>
                    <p class="fw-semibold opacity-50 mt-4">
                      With HelixPay, Yardstick built a community for their most customers. In the program,
                      members can subscribe and save to their products and other exclusive merch. They use
                      the Email Blast feature for any announcements, and recently they dropped an exclusive
                      YCC merch - the Coffee Person cap.
                    </p>
                  </div>
                  <div class="partners-right">
                    <img src="/img/yardstick-blog.jpeg" class="card-img-top" alt="...">
                  </div>
                </div>

                <!-- AWKP -->
                <div class="partners mt-4">
                  <div class="partners-left">
                    <div class="h5 fw-semibold mt-3">
                      Ang Walang Kwentang Podcast
                    </div>
                    <p class="fw-semibold opacity-50 mt-4">
                      AWKP’s starter pücks (or their merch) played a big role in the success of their website launch.
                      They have also built a solid (and thriving) community of pückers, who get to enjoy extra
                      discount to their merch.
                      <br>
                      AWKP makes use of the email blast feature to remind the pückers to use their unique code,
                      enabling them to a 5% discount on all merch items. They also used the email blast feature
                      to announce their free shipping promo during the 9.9 weekend. No need for Lazada or Shopee
                      partnership - you can do it all on HelixPay.
                    </p>
                  </div>
                  <div class="partners-right">
                    <img src="/img/awkp-blog.png" class="card-img-top" alt="...">
                  </div>
                </div>

                <!-- Adam Alejo -->
                <div class="partners mt-4">
                  <div class="partners-left">
                    <div class="h5 fw-semibold mt-3">
                      Adam Alejo
                    </div>
                    <p class="fw-semibold opacity-50 mt-4">
                      Sending out important dates to remember for the new month helps drive higher retention.
                      This gives them an idea of know what to expect and the perks that they get out of their
                      subscription.
                    </p>
                  </div>
                  <div class="partners-right">
                    <img src="/img/adamalejo-blog.png" class="card-img-top" alt="...">
                  </div>
                </div>
              </div>
            </div>

            <div v-if="layout === 'VMG'" class="output-stories m-auto">
              <div class="card p-5">
                <div class="h4 fw-bold">
                  <img src="/img/viyline-company-picture.jpg" class="card-img-top" alt="...">
                </div>
                <p class="fw-semibold opacity-50">
                  <br>
                  As the partner of Lincoln Velasquez (Cong TV), almost anyone who’s familiar with vlogging in the Philippines knows that Viy Cortez is one of the most successful and influential content creators and businesswomen in the country.
                  <br>
                  <br>
                  With funny and entertaining videos online, Viy has accumulated and continues to grow her 6.1 million subscribers on YouTube. Her channel encapsulates her daily life inside the Payamansion ranging from mundane things like cooking with her boyfriend, Cong, to doing make-up tutorials to facilitating friendly challenges and pranks and many more!
                  <br>
                  <br>
                  With a positive outlook in life and the support of people around her, Viy established herself as a successful influencer, landing brand deals and amassing wealth from her humble beginnings. Amongst other things, she built her parents their dream house with the money she earned. And just recently she also started the construction of her own dream house with long-time boyfriend, now fiancé, Cong.
                  However, unbeknownst to many, a huge part of Viy’s success is her entrepreneurial mindset – being super creative in her contents but also not wanting to be complacent and dependent on social media platforms as her lone source of income. She sought for a more sustainable way to earn and founded her own cosmetic line, Viy Line Cosmetics. This project that started with a 10k PHP investment from Cong, eventually grew to become the Viy Group of companies – expanding from cosmetics to clothing, skincare and even food & beverages.
                  <br>
                  <br>
                  Viy’s rising fame in what seemed back then to be non-traditional media, YouTube, has put her in a position of influence on par with that of a celebrity. What started off as an independent creator channel has evolved into a full-blown brand behind her massive online following. But it didn’t have to be big for her to start her venture.
                  <br>
                  <br>
                  When she started Viy Line back in 2019, her subscriber count was only within the six-digit (100k-250k) range. However, by recognizing that her relationship and engagement with her audience goes beyond the screen, she saw a huge opportunity in building out a business through her community. Since she started her channel with makeup tutorials, it made sense to create a cosmetic line.  She carved an entry point to the market through her engaged fans and now Viy Line Cosmetics can stand on its own, growing with her online presence, and generating millions of pesos annually.
                  <br>
                  <br>
                  This is just one of many examples of creators expanding from content creation to become successful entrepreneurs. Vloggers and social media personalities are garnering equal and sometimes even more viewership than TV productions. 20 years ago, ads spaces were bought from TV networks for commercial air time. 10 years ago, brands capitalizing on the followers and subscribers of influencers offered them content sponsorships for product promotion. Now more than ever, content creators, with advancements in e-commerce adoption, are in the position to promote their OWN brand instead of others.
                  <br>
                  <br>
                  HelixPay is building Philippine’s first e-commerce technology for content creators enabling you to easily bring your ideas to life! We have already helped some of the biggest creators in the country to launch their ecommerce businesses. If you have any physical / digital products in mind, we can help you bring this to market using a combination of our proprietary technology and our professional network. It’s time to grasp this opportunity and influence your own future!
                </p>
              </div>
            </div>

            <div v-if="layout === 'Upload'" class="output-stories m-auto">
              <div class="card p-5">
                <div class="h4 fw-bold">
                  Upload Videos and Blogs on your HelixPay Members Page
                </div>
                <p class="fw-semibold opacity-50">
                  <br>
                  Hey creator!
                  <br>
                  <br>
                  Want to offer an exclusive type of fan memberships for your followers? Well, HelixPay can help you with that! You can create your own exclusive, private, and member only page. In this page you can create various types of content: videos, blog posts, podcasts, and comics. Since this is a completely exclusive feed, your customers and fans can pay a certain amount of money for content that won’t be available anywhere else. This access is customizable depending on your different membership tiers or packages/programs. For instance, for a basic membership you can add three videos, for a standard membership 5 videos, but for a premium membership or your highest tier, you can add 10 videos with a blog post and a comic. Every content can be filtered and only those who bought your membership can see this content. All of these details are fully customizable according to your plans and preferences.
                  <br>
                  <br>
                  This can empower you as a creator to build your own Netflix-style video on demand experience. For Youtubers and Streamers, you can offer never seen before content, or you can give your subscribers early releases of your new content. For coaches or gurus, you can offer module lessons or courses. The power is ultimately up to you. This can completely change the way you interact and engage with your fans and empower you to earn big recurring sustainable income.
                  <br>
                  <br>
                  Want to have your own membership page? Request for a demo and schedule a call now!
                </p>
              </div>
            </div>

            <div v-if="layout === 'Perks'" class="output-stories m-auto">
              <div class="card p-5">
                <div class="h4 fw-bold">
                  Hey creator!
                  <br>
                  <br>
                  Do you feel like you lack content and offerings for your subscribers/fans? Well here are ideas for more features to add for your membership packages!
                </div>
                <div class="fw-semibold opacity-50">
                  <br>
                  <br>
                  Zoom meetings
                  <br>
                  <br>
                  One way to spice up your content is by setting up Zoom meetups. You can do online shows or live hangouts with your community via Zoom for up to 500 attendees!
                  <br>
                  <div class="p-2">
                    - Classes - perfect for financial and life coaches, yoga instructors, and beauty gurus.<br>
                    - Live recordings - perfect for streamers and podcasts, where they can invite guests for interviews, play alongs, or roundtable discussions.<br>
                    - Q & A sessions - allow fans or subscribers to ask you about anything or could even just focus on specific topics.
                  </div>
                </div>
                <img src="/img/zoom-meetings.jpg" class="card-img-top w-75" alt="...">
                <br>
                <br>
                <div class="fw-semibold opacity-50">
                  Discord Integration
                  <br>
                  <br>
                  Discord is one of the most popular chat apps especially in the gaming and creator communities. HelixPay has a special integration directly with Discord for our creator clients that makes it easy and seamless for your subscribers to join Discord automatically when they have paid for your membership packages. There are many features in Discord; you can customize what your members can access based on their subscription tier. If you have different tiers of membership such as Basic, Standard, and Premium, you can filter out every content and channels available for each subscriber. You can also set up voice channels in your private discord server where other members can chat. And you can schedule activities that would boost engagement such as games, quiz nights, etc.
                </div>
                <br>
                <br>
                <img src="img/discord-img-min.png" class="w-50" alt="">
                <br>
                <br>
                <div class="fw-semibold opacity-50">
                  Members only discount
                  <br>
                  <br>
                  Aside from offering memberships and subscriptions, you can also sell one time purchases such as merchandise wherein you can create a unique voucher code per member. If you’re an affiliate and have partnerships with various brands, you can also offer special codes that would give them a discount to your partner brands.
                </div>
                <br>
                <br>
                <img src="img/awkp-screenshot.jpg" class="w-75" alt="">
                <br>
                <br>
                <div class="fw-semibold opacity-50">
                  Access to your member’s page
                  <br>
                  <br>
                  With HelixPay you can upload videos, blogs, and voice notes on your exclusive content page. Apart from the content you put out on your public social media accounts, you can upload extended cuts, behind-the-scenes, bloopers, and never-seen-before content to maintain the exclusivity of your member’s page. You can set which content is available to which people based on your products and membership tiers. For instance, you can offer a subscription that gives access to all of your videos. You can also offer a “single-purchase” product that gives a fan access to one particular video.
                  <br>
                  <br>
                  Customers buy access directly from your storefront. They can watch the videos with a simple log-in via their personal SMS number so it’s easy for fans to watch while still being very secure so only your paying fans can access the content.
                  <br>
                  <br>
                  To conclude, there are many things you can do as a content creator in HelixPay. Request for a demo and schedule a call now!
                </div>
              </div>
            </div>

            <div v-if="layout === 'Raux'" class="output-stories m-auto">
              <div class="card p-5">
                <div class="h4 fw-bold">
                  Hey Rifters!
                  <br>
                  <br>
                </div>
                <p class="fw-semibold opacity-50">
                  Mga dude pare, Raux, has now launched his own website! After his prolific career in League of Legends - PC and his recent stint in WildRift as the support for Team Secret, Raux has decided to share his love for the game to his followers through highly engaging and super chill streams. He still plays WildRift, boasting highly skilled Challenger-level matches on his Facebook page/ Twitch channels. Catch him live on your regular late afternoon with his over 60k followers! All the while demonstrating his prowess, Raux continues to inspire on-stream as he engages with his audience very openly, especially when educating them about the game.
                </p>
                <br>
                <img src="/img/raux-min.jpg" class="card-img-top w-50" alt="...">
                <br>
                <p class="fw-semibold opacity-50">
                  With nine years of playing League under his belt, a knack for teaching, and a chill tone guiding his teammates, aspiring players have from a range of skill levels reached out to him for coaching services. Continuously looking for ways to connect with his fans and promote the game, he created a website together with HelixPay to compile all his memberships, tutorials, and fan experiences together. In just one website, Raux can easily manage his services enabling him to run a business and pursue a streaming career seamlessly!
                </p>
                <p class="fw-semibold opacity-50">
                  Visit his storefront now - rauxtv.helixpay.ph
                </p>
                <img src="/img/raux-screenshot-min.jpg" class="card-img-top w-75" alt="...">
                <p class="fw-semibold opacity-50">
                  Are you a streamer like Raux? Book a call with us now!
                </p>
              </div>
            </div>

            <div v-if="layout === 'Arykingking'" class="output-stories m-auto">
              <div class="card p-5">
                <div class="h4 fw-bold">
                  Arynkingking launches 8-bit HelixPay storefront
                </div>
                <p class="fw-semibold opacity-50">
                  <br>
                  Aryn Cristobal or Arynkingking is finally launching her own Helixpay storefront! Vlogger, podcaster, singer, and many more - she loves creating comedic and relatable content for millennials. She does everything from improvising songs, talking about the latest TV shows, and sharing love advice. Her style and content have made her one of the most popular personalities in the country!
                </p>
                <img src="/img/aryn-min.jpg" class="card-img-top w-50" alt="...">
                <p class="fw-semibold opacity-50">
                  On her storefront, fans can easily buy and enjoy unique experiences. She’s launching exciting fan subscription packages with three membership tiers, where her fans can join her private Discord community and they’ll also gain access to her exclusive content. She has also set up a tip jar where you can give her any amount, starting at Php 50. Another exciting offering from Aryn is the chance to have a 15-minute chat with her. You can ask her anything or you can also do an improv game with her as you record. This also entitles you to a discount at Third World Improv. Exciting, right?
                </p>
                <p class="fw-semibold opacity-50">
                  Her offerings range from just P50 up to P300. If you’re one of her big fans, head on to her site right now  - Arynkingking.com
                </p>
                <img src="/img/aryn-screenshot.jpg" class="card-img-top w-75" alt="...">
              </div>
            </div>

            <div v-if="layout === 'THOC'" class="output-stories m-auto">
              <div class="card p-5">
                <div class="h4 fw-bold">
                  HelixPay provides e-commerce technology for The House Of Collab
                </div>
                <img src="/img/thoc-header-pic.png" class="card-img-top" alt="...">
                <p class="fw-semibold opacity-50">
                  Hey THOC fans, we have exciting news for you!
                  <br>
                  <br>
                  House of Talents is an online reality show of content creators living together in a mansion. Currently, they are on its third season and millions of people have tuned in over the past few months. The group is active across social media platforms - they shoot a 24/7 livestream in Kumu, have scheduled live shows on Twitch, Facebook, and on local TV channels, and post Youtube episodes daily. With over 25 content creators, each one has hundreds of thousands of followers and various partnerships with many brands.
                  <br>
                  <br>
                  Because of this, many of their fans would message them and ask for requests like an audio or a video shoutout. Remember in that one episode where Evan and Khate had a dancing showdown? Your favorite content creator can send you a video of them dancing or singing as they prepare your own personalized video. Some of the members of House of Talents have recently launched their storefronts such as Karl Limpin, Ward Reinalyn, and Angge Gegante. They make use of HelixPay for easier payment management. Moreover, with HelixPay, you can showcase your own style and branding. With HelixPay’s no code storefronts, it is easy and hassle-free to make your own ecommerce website. You can change anything like the fonts, colors and style seamlessly. No need to learn any sort of software coding!
                  <br>
                  <br>
                  Helixpay has been a helping hand to influencers and content creators to enable their earnings with e-commerce technology. Need help as well? Message us and request for a demo now!
                </p>
              </div>
            </div>

            <div v-if="layout === 'HxP Insights'" class="output-stories m-auto">
              <div class="card p-5">
                <div class="h4 fw-bold">
                  Hey creator!
                  <br>
                  <br>
                  Thinking of possible products/services to sell from your HelixPay storefront? There are infinite possibilities  but the first thing we recommend is to sell your simplest products: simple tip jar, shoutouts, fan merch, and exclusive fan subscriptions.
                  <br>
                  <br>
                </div>
                <p class="fw-semibold opacity-75">
                  Tip jar
                </p>
                <p class="fw-semibold opacity-50">
                  This is the easiest one to start with. You can start and soft launch a tip jar. A tip jar enables your audience to give back and express appreciation without having to spend a fixed amount - they can give an amount that works for them.
                </p>
                <p class="fw-semibold opacity-75">
                  Shoutout
                </p>
                <p class="fw-semibold opacity-50">
                  Most often used by streamers and podcasts, shoutouts are one of the best ways of creating revenue. Shout-out posts help show the connection between you and your subscribers and can help you gain new followers. When you shout out to subscribers/fans, their friends are able to see your post. (mention cameo - creators and celebrities in the US are earning really well from cameo!).
                </p>
                <p class="fw-semibold opacity-75">
                  Merchandise
                </p>
                <p class="fw-semibold opacity-50">
                  You can also start selling merchandise. These can be anything ranging from caps, hoodies, stickers, candles, and much more. Moreover, Helixpay has a partnership with local logistics companies in the Philippines.
                </p>
                <p class="fw-semibold opacity-75">
                  Subscriptions
                </p>
                <p class="fw-semibold opacity-50">
                  Lastly, you can build a strong community with your fans by setting up different kinds of subscriptions. In these subscriptions you can create an exclusive invite to Discord/Telegram, you can send them a never seen before footage, or you can give them early access to your content.
                </p>
                <p class="fw-semibold opacity-75">
                  Create your own
                </p>
                <p class="fw-semibold opacity-50">
                  You can create any type of product or experience to sell from your storefront. It’s easy to customize and add a new product in just minutes. You can read more about how to create a product
                  <span class="fw-semibold opacity-50" onclick="window.open('https://docs.helixpay.ph/docs/6-managing-product-offers')">
                    here.
                  </span>
                </p>
                <p class="fw-semibold opacity-50">
                  To conclude, the options are limitless. As long as the prices are affordable enough for your audience, and you are able to market and showcase your storefront to your followers, you are able to set up a profitable and successful venture that generates big sustainable income for your creative pursuits. Book a meeting with us now!
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>

    <CCLaunchSubscriptions />
    <MainCCFooter />
  </div>
</template>

<script>

import CCLaunchSubscriptions from '@/components/CCLaunchSubscriptions.vue';
import HeaderNavInsights from '@/components/HeaderNavInsights.vue';
import MainCCFooter from '@/components/MainCCFooter.vue';
import { buildPageMeta } from '@/utils/meta';
import { useHead } from '@vueuse/head';

export default {
  name: 'Insights',
  components: {
    HeaderNavInsights,
    CCLaunchSubscriptions,
    MainCCFooter
  },

  setup() {
    useHead(buildPageMeta({
      title: "Insights | Ecommerce Technology for Creators & Communities",
      description: 'Select option that is the best fit for your business needs. HelixPay Checkout is custom built for subscriptions and recurring payments.',
      url:  'https://www.helixpay.ph/insights',
      image:  'https://www.helixpay.ph/img/creators-meta.jpg',
    }));
  },

  data: () => ({

    layout: 'All',

  }),
};

</script>

<style scoped>

.ig-img {
  width: 13rem;
}

#igMobile {
  display: none;
}

.list-group button {
  font-weight: 600;
  opacity:0.5;
  cursor: pointer;
  border: none;
  background-color: #f3f7f8;
}

.list-group button:not(:last-child) {
  border-bottom: 1px solid black;
}

.list-group button:focus {
  font-weight: 700;
  opacity: 1;
}

.insights-header,
.latest-creator {
  width: 100%;
}

.insights-header-info {
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 1fr;
  gap: 5rem;
  max-width: 75rem;
  margin: auto;
  padding: 0 2rem;
}

.insights-header-info h1 {
  font-weight: 800;
  font-size: 7.5rem;
  color: #FF5F8F;
}

.insights-header-info p {
  width: 75%;
  margin-top: 3rem;
}

.latest-creator-info {
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 1fr;
  gap: 5rem;
  width: 71rem;
  margin: auto;
  margin-bottom: 3rem;
  padding: 3rem;
  border-radius: 15px;
  background-color: white;
  box-shadow: 6px 5px 0px 11px rgba(0,0,0,1);
  -webkit-box-shadow: 6px 5px 0px 11px rgba(0,0,0,1);
  -moz-box-shadow: 6px 5px 0px 11px rgba(0,0,0,1);
}

.latest-creator-info img {
  border-radius: 5px;
  border-radius: 20px;
}

.scrolling,
.section-title {
  display: none;
}

.insights {
  width: 100%;
}

.insights-info {
  width: 71rem;
  margin: auto;
  display: grid;
  align-items: start;
  grid-template-columns: 1fr 3fr;
  margin-top: 10rem;
  padding-bottom: 5rem;
  gap: 3rem;
}

.no-bg {
  background-color: white;
  color: black;
}

.pink-section {
  padding-top: 1rem;
  padding-bottom: 1rem;
  background-color: black;
}

.pink-section:hover {
  color: white;
  background-color: black;
}

.floating-title {
  border-bottom: 3px solid black;
}

.left-part {
  position: -webkit-sticky;
  position: sticky;
  top: 2rem;
}

.categories {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgb(88, 88, 88, 0.1);
}

.dropdown-divider {
  height: 2px;
  opacity: 50;
  color: black;
  background-color: black;
}

#ig-mobile-photos {
  width: 95%;
  margin: auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0.5rem;
}

#ig-mobile-photos img {
  width: 100%;
  border-radius: 15px;
  cursor: pointer;
  transition: 0.2s ease-in;
  /* box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); */
}

#ig-mobile-photos img:hover {
  transform: scale(1.02);
}

.categories a {
  text-align: left;
}

.opacity-50 {
  line-height: 1.5;
  font-size: 1rem;
  margin-top: 0.5rem;
}

.card-text {
  line-height: 1.5;
}

.card {
  width: 100%;
  cursor: pointer;
  border: none;
  border-radius: 15px;
  transition: 0.2s ease-in;
  box-shadow: 6px 5px 0px 11px rgba(0,0,0,1);
  -webkit-box-shadow: 6px 5px 0px 11px rgba(0,0,0,1);
  -moz-box-shadow: 6px 5px 0px 11px rgba(0,0,0,1);
}

.card-img-top {
  border-radius: 30px;
}

.dropdown-output {
  line-height: 2;
  padding: 2rem;
  background-color: none;
}

.card img {
  padding: 1rem 1rem 0 1rem;
}

.card:hover {
  transform: scale(1.01);
}

.output-cards {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 3rem;
}

.pagination {
  margin: auto!important;
}

main {
  background-color: #f3f7f8;
}

.partners {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

.partners-mobile {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
}

@media only screen and (max-width: 1200px) {

    .insights-info,
    .latest-creator-info {
      width: 90%;
    }

    .card {
      width: 100%;
    }

    .left-part img {
      width: 60%;
      margin: auto;
    }

}

@media only screen and (max-width: 900px) {

    .insights-info,
    .latest-creator-info {
      grid-template-columns: 1fr;
      gap: 1rem;
    }

    .insights-header-info {
      grid-template-columns: 1fr;
      gap: 0;
    }

    .insights-header-info h1 {
      font-size: 3rem;
    }

    .insights-header-info p {
      margin-top: 0;
      margin-bottom: 3rem;
    }

    .left-part {
      display: none;
    }

    .left-part button {
      margin: 0;
      font-size: 1rem;
    }

    .insights {
      display: none;
    }

    #mobileOnly {
      display: grid;
      grid-auto-flow: column;
      grid-auto-columns: 63%;
      gap: 1rem;
      padding-top: 1rem;
      padding-bottom: 1rem;

      overflow-x: auto;
      overscroll-behavior-inline: contain;
    }

    #mobileOnly::-webkit-scrollbar {
      display: none;
    }

    #igMobile {
      display: block;
    }

    .scroll-element {
      padding: 0.5rem;
    }

    .snaps-inline {
      scroll-snap-type: inline mandatory;
    }

    .snaps-inline > * {
      scroll-snap-align: start;
    }

    .title {
      width: 100%;
    }

    .section-title {
      display: block;
      padding-top: 1rem;
      width: 89.5%;
      margin: auto;
    }

    .blog-post-card {
      width: 100%;
      display: grid;
      grid-template-columns: 3fr 1fr 3fr;
      align-items: center;
      padding: 1.5rem;
      border-radius: 5px;
      cursor: pointer;
      transition: 0.2s ease-in;
    }

    .blog-post-card:hover {
      transform: scale(1.01);
    }

    .blog-post-card img {
      width: 90%;
      margin: auto;
      display: block;
    }

}

@media only screen and (max-width: 650px) {

    .output-cards {
      grid-template-columns: 1fr;
    }

    .left-part img {
      width: 100%;
    }

    .read-more {
      width: 100%;
    }

    .right-part h4 {
      font-size: 1rem;
    }

    .right-part h1 {
      font-size: 1.5rem;
    }

}
</style>